import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./index.css";
import "./contact.css";
import Nav from "../components/nav"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact";
import Hero from "../components/hero-standard"
import Footer from "../components/footer";

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Contact" />
        <Nav activeTab="contact" />
        <div>
            <div>

              <Hero h1="Contact" h2="How to get in touch with us" />

              <div className="contact-page">
                <div className="article">
                  <div className='article-inner'>
                    <div data-aos="fade-up" data-aos-duration="800" className="contact__passengers contact__section">
                      <h2>Parents, passengers &amp; personal accounts</h2>
                      <p>If you are a parent, passenger or account holder in need of help with purchasing or using your travel tickets, please visit our Help section for frequently asked questions and how to contact the ShuttleID technical team.</p>
                      <Link to="/help/" className="btn">Help</Link>
                    </div>

                    <div className="contact__section">
                      <h2>Transport operators, schools &amp; businesses</h2>
                      <p>If you are a business or organisation interested in hearing more about how ShuttleID can help, please contact our sales team using the below details or use the enquiry form to get in touch.</p>
                      <div className="contact__details">
                        <div className="contact__details-col">
                          <p><strong>Email:</strong><br />info@shuttleid.uk</p>
                          <p><strong>Phone:</strong><br />0333 344 9868</p>
                        </div>
                        <div className="contact__details-col">
                          <p>
                            <strong>Address:</strong> <br />
                            ShuttleID<br />
                            46 Park Place<br />
                            Leeds<br />
                            LS1 2RY
                          </p>
                        </div>
                        <div className="contact__details-col">
                          <p>
                            <strong>Opening hours:</strong> <br />
                            Mon-Fri<br />
                            9AM-5PM
                          </p>
                        </div>
                      </div>

                      <h3>Make a business enquiry</h3>
                      <form action="/contact-submitted/" name="contact" method="POST" className="contact-form" data-netlify="true" data-netlify-honeypot="bot-field">
                        <p style={{ display: 'none' }}>
                          <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                        </p>
                        <div className="contact-form__group">
                          <label>Your name *</label>
                          <input placeholder="Your name *" name="name" required className="contact-form__input" />
                        </div>
                        <div className="contact-form__group">
                          <label>Company *</label>
                          <input placeholder="Company *" name="company" required className="contact-form__input" />
                        </div>
                        <div className="contact-form__group">
                          <label>Email *</label>
                          <input placeholder="Email *" name="email" type="email" required className="contact-form__input" />
                        </div>
                        <div className="contact-form__group">
                          <label>Phone *</label>
                          <input placeholder="Phone *" name="phone" required className="contact-form__input" />
                        </div>
                        <div className="contact-form__group">
                          <label>Message *</label>
                          <textarea placeholder="Message *" name="message" required className="contact-form__input" />
                        </div>
                        <input type="hidden" name="form-name" value="contact" />
                        <p>For parents or passengers with bus service queries, please do not use this form as this will cause significant delay to your message being handled. Instead, please visit our <Link to="/help/">Help section</Link> for answers to common queries and for contact details of the ShuttleID technical team.</p>
                        <p>
                          <button className="btn" type="submit">Submit enquiry</button>
                        </p>
                      </form>
                    </div>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
